import React from 'react';
import styles from './FindHeaderHowItWorks.module.scss';
import tellUs from './1-tell-us-what-you-need.png';
import answerQuestion from './2-answer-a-few-questions.png';
import getPitches from './3-creatives-will-pitch-to-you.png';

const FindHeaderHowItWorks = ({ ...rest }) => {
  return (
    <div {...rest}>
      <div className={styles.steps + ' mr'}>
        <img src={tellUs} alt="Tell us what you need" />
      </div>
      <div className={styles.greyDot + ' hidden-xs'}></div>
      <div className={styles.greyDot + ' hidden-xs'}></div>
      <div className={styles.greyDot + ' hidden-xs'}></div>
      <div className={styles.greyDot + ' hidden-xs'}></div>
      <div className={styles.greyDot}></div>
      <div className={styles.steps + ' ml mr'}>
        <img src={answerQuestion} alt="Answer a few questions" />
      </div>
      <div className={styles.greyDot + ' hidden-xs'}></div>
      <div className={styles.greyDot + ' hidden-xs'}></div>
      <div className={styles.greyDot + ' hidden-xs'}></div>
      <div className={styles.greyDot + ' hidden-xs'}></div>
      <div className={styles.greyDot}></div>
      <div className={styles.steps + ' ml'}>
        <img src={getPitches} alt="Creative freelancers will pitch to you!" />
      </div>
      <br />
      <div className={styles.stepTextLeft}>Tell us what you need</div>
      <div className={styles.stepText}>Answer a few questions</div>
      <div className={styles.stepTextRight}>
        Creative freelancers will pitch to you!
      </div>
    </div>
  );
};

export default FindHeaderHowItWorks;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Grid } from 'react-bootstrap';
import VisibilitySensor from 'react-visibility-sensor';
import CategoryCard from 'components/Categories/CategoryCard/CategoryCard';
import styles from './CategoryCards.module.scss';

export default class CategoryCards extends Component {
  static propTypes = {
    className: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    design: PropTypes.oneOf(['image', 'illustration']),
  };

  static defaultProps = {
    design: 'image',
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const { categories, design, className, ...rest } = this.props;

    const { visible } = this.state;

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 100}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <Grid
          className={
            (design === 'image' ? '' : styles.containerIllustration) +
            (className ? ' ' + className : '')
          }
          {...rest}
        >
          {categories.map((category, idx) => {
            return (
              <Col
                className={styles.col + (visible ? '' : ' ' + styles.hide)}
                xs={12}
                sm={design === 'image' ? 12 : 6}
                md={design === 'image' ? 6 : 4}
                lg={design === 'image' ? 4 : 4}
                key={idx}
              >
                <CategoryCard
                  design={design}
                  title={category.title}
                  link={category.link}
                  image={category.image}
                  roles={category.roles || []}
                />
              </Col>
            );
          })}
        </Grid>
      </VisibilitySensor>
    );
  }
}

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'components/LazyLoad/LazyLoad';

import styles from './CategoryCard.module.scss';

const CategoryCard = ({ design, title, link, image, roles = [] }) => {
  return (
    <LazyLoad>
      <Link
        to={link}
        className={
          styles.container +
          (design === 'image' ? '' : ' ' + styles.illustration)
        }
        style={{ backgroundImage: image ? `url(${image})` : '' }}
        title={title}
      >
        <div className={styles.overlay}>
          {roles.length > 0 && (
            <div className={styles.overlayText}>
              {roles.map((role) => {
                return <p key={role}>{role}</p>;
              })}
              <p>and more...</p>
            </div>
          )}
          <div className={styles.overlayBackground} />
        </div>
        <div className={styles.text}>
          <span>{title}</span>
        </div>
      </Link>
    </LazyLoad>
  );
};

CategoryCard.propTypes = {
  design: PropTypes.oneOf(['image', 'illustration']),
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.string,
  roles: PropTypes.array,
};

CategoryCard.defaultProps = {
  design: 'image',
};

export default CategoryCard;

import React from 'react';

import styles from './HeaderWithBackgroundPattern.module.scss';
import backgroundImage from './background.png';

const HeaderWithBackgroundPattern = ({
  children,
  className = '',
  style = {},
  ...rest
}) => {
  return (
    <div
      className={`${className} ${styles.pattern}`}
      style={{ ...style, backgroundImage: `url(${backgroundImage})` }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default HeaderWithBackgroundPattern;

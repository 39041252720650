import PropTypes from 'prop-types';
import React from 'react';

import CategoryCards from 'components/Categories/CategoryCards/CategoryCards';

import imageGraphicDesign from './graphic-design.jpeg';
import imageDigitalMarketing from './digital-marketing.jpeg';
import imageVideoAndAnimation from './video-and-animation.jpeg';
import imageMusic from './music.jpeg';
import imageProgramming from './programming.jpeg';

import illustrationGraphicDesign from './graphic-design.png';
import illustrationDigitalMarketing from './digital-marketing.png';
import illustrationVideoAndAnimation from './video-and-animation.png';
import illustrationMusic from './music.png';
import illustrationProgramming from './programming.png';
import illustrationStartups from './startups.png';

const TopCategories = ({ design, ...rest }) => {
  const data = [
    {
      title: 'Video & Animation',
      image:
        design === 'illustration'
          ? illustrationVideoAndAnimation
          : imageVideoAndAnimation,
      link: '/hire-a-freelancer/video-and-animation',
      roles: ['Videographer', 'Explainer', 'Video Animation'],
    },
    {
      title: 'App & Web Development',
      image:
        design === 'illustration' ? illustrationProgramming : imageProgramming,
      link: '/hire-a-freelancer/programming',
      roles: ['Web Developer', 'Programmer', 'App Design'],
    },
    {
      title: 'Graphic & Design',
      image:
        design === 'illustration'
          ? illustrationGraphicDesign
          : imageGraphicDesign,
      link: '/hire-a-freelancer/graphic-design',
      roles: ['Graphic Designers', 'Logo Design', 'Branding'],
    },
    {
      title: 'Music & Audio',
      image: design === 'illustration' ? illustrationMusic : imageMusic,
      link: '/hire-a-freelancer/music',
      roles: ['Music Producer', 'Vocalist', 'Film Composer'],
    },
    {
      title: 'Digital Marketing',
      image:
        design === 'illustration'
          ? illustrationDigitalMarketing
          : imageDigitalMarketing,
      link: '/hire-a-freelancer/digital-marketing',
      roles: ['Logo Design', 'Branding', 'Pitch Deck'],
    },
    {
      title: 'Business & Startups',
      image: design === 'illustration' ? illustrationStartups : '',
      link: '/hire-a-freelancer/business',
      roles: ['Explainer Video', 'Product Design', 'Business Cards'],
    },
  ];

  return <CategoryCards design={design} categories={data} {...rest} />;
};

TopCategories.propTypes = {
  design: PropTypes.oneOf(['image', 'illustration']),
};

TopCategories.defaultProps = {
  design: 'image',
};

export default TopCategories;

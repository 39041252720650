import React from 'react';
import FindHeaderHowItWorks from 'components/Find/FindHeaderHowItWorks/FindHeaderHowItWorks';
import HeaderWithBackgroundPattern from 'components/HeaderWithBackgroundPattern/HeaderWithBackgroundPattern';
import TopCategories from 'components/Categories/TopCategories/TopCategories';

const NotFoundContent = ({
  title = 'Page not found',
  subtitle,
  status404 = true,
}) => {
  // This page MUST render the string '__404-page__' to ensure the server returns a 404 status
  return (
    <div className={status404 ? '__404-page__' : ''}>
      <HeaderWithBackgroundPattern>
        <h1
          style={{
            color: '#fff',
            textAlign: 'center',
            padding: '40px 20px',
            display: 'block',
            textTransform: 'uppercase',
            margin: 0,
          }}
        >
          {title}
        </h1>
        {subtitle ? <div style={{ marginTop: '-20px' }}>{subtitle}</div> : null}
        <FindHeaderHowItWorks className="text-center pb++" />
      </HeaderWithBackgroundPattern>
      <TopCategories design="illustration" />
    </div>
  );
};

export default NotFoundContent;
